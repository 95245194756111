import { isEmpty } from 'lodash';

import { ButtonGroup } from '@/components/button-group';
import { Container } from '@/components/container';
import { BlockProps, KeyedObjInterface } from '@/types/block-types';
import { cn, tv } from '@/utils/styles';

import { BlockHeader } from './header';

export const blockAlignmentOptions: KeyedObjInterface = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
};

const Block = ({
  blockAlignment = 'left',
  body = null,
  buttons,
  children = null,
  contentColumns = {
    left: 'w-full md:w-9/12',
    center: 'w-full md:w-9/12',
    right: 'w-full md:w-9/12',
  },
  hasButtonsAtTheBottom = false,
  heading,
  headingVariant = 'h1',
  headingElement = 'h2',
  id,
  preHeading,
  preHeadingVariant = 'uppercase',
  hasSlimContainer,
  hasSkinnyContainer,
  noTopPadding,
  textColorVariant,
  bodySize,
  className,
}: BlockProps) => {
  const { base, inner, outer, buttonGroup, buttonGroupWrapper } = blockStyles({
    hasSlimContainer,
    hasSkinnyContainer,
    blockAlignment,
    noTopPadding,
  });

  return (
    <section id={id} className={base({ className })}>
      <Container>
        <div className={outer()}>
          {(heading || body || !isEmpty(buttons)) && (
            <header className={contentColumns[blockAlignment]}>
              <div className={inner()}>
                <BlockHeader
                  preHeading={preHeading}
                  heading={heading}
                  headingElement={headingElement}
                  headingVariant={headingVariant}
                  body={body}
                  bodySize={bodySize}
                  blockAlignment={blockAlignment}
                  preHeadingVariant={preHeadingVariant}
                  variant={textColorVariant}
                />

                {!isEmpty(buttons) && !hasButtonsAtTheBottom && (
                  <ButtonGroup buttons={buttons} className={buttonGroup({ class: 'mt-8 lg:mt-12' })} size="lg" />
                )}
              </div>
            </header>
          )}
          {children && <div className="w-full">{children}</div>}

          {!isEmpty(buttons) && hasButtonsAtTheBottom && (
            <div className={cn(contentColumns[blockAlignment], 'mt-8')}>
              <div className={buttonGroupWrapper()}>
                <ButtonGroup buttons={buttons} className={buttonGroup()} size="lg" />
              </div>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

export const blockStyles = tv({
  slots: {
    base: 'block-wrapper mx-auto w-full py-16 xl:py-24',
    inner: 'mb-8 lg:mb-12',
    outer: 'flex flex-wrap',
    buttonGroup: null,
    buttonGroupWrapper: null,
  },
  variants: {
    hasSlimContainer: {
      true: 'max-w-5xl',
    },
    hasSkinnyContainer: {
      true: 'max-w-[600px]',
    },
    blockAlignment: {
      left: {
        inner: 'text-left',
        outer: 'justify-start',
        buttonGroupWrapper: 'text-left',
        buttonGroup: 'justify-start',
      },
      center: {
        inner: 'text-center',
        outer: 'justify-center',
        buttonGroupWrapper: 'text-center',
        buttonGroup: 'justify-center',
      },
      right: {
        inner: 'text-right',
        outer: 'justify-end',
        buttonGroupWrapper: 'text-right',
        buttonGroup: 'justify-end',
      },
    },
    noTopPadding: {
      true: {
        base: 'pt-0 sm:pt-0 md:pt-0 lg:pt-0 xl:pt-0 2xl:pt-0 3xl:pt-0',
      },
    },
  },
});

export { Block };
