import { Heading } from '@/components/heading';
import { RichText } from '@/components/rich-text';
import { Text } from '@/components/text';
import { BlockHeaderProps } from '@/types/block-types';
import { tv } from '@/utils/styles';

const BlockHeader = ({
  preHeading,
  preHeadingVariant = 'uppercase',
  heading,
  headingVariant = 'h1',
  headingElement = 'h2',
  body,
  className,
  blockAlignment = 'left',
  variant = 'light',
  bodySize = 'lg',
}: BlockHeaderProps) => {
  const { preHeadingStyles, headingStyles, richText, base } = blockHeaderStyles({
    blockAlignment,
    variant,
    bodySize,
  });

  return (
    <div className={base({ className })}>
      {preHeading && (
        <Text variant={preHeadingVariant} className={preHeadingStyles()}>
          {preHeading}
        </Text>
      )}
      {heading && (
        <Heading variant={headingVariant} className={headingStyles()} as={headingElement}>
          {heading}
        </Heading>
      )}
      {body && <RichText className={richText()}>{body}</RichText>}
    </div>
  );
};

export const blockHeaderStyles = tv({
  slots: {
    base: null,
    headingStyles: null,
    preHeadingStyles: 'block-pre-heading mb-3 text-lightBlue-500',
    richText: 'block-rich-text max-w-3xl text-baseLg leading-7 lg:text-md lg:leading-snug',
  },
  variants: {
    blockAlignment: {
      left: {
        base: 'text-left',
        richText: 'mx-0',
      },
      center: {
        base: 'text-center',
        richText: 'mx-auto',
      },
      right: {
        base: 'text-right',
        richText: 'mx-0',
      },
    },
    variant: {
      light: {
        headingStyles: 'text-blue-800',
        richText: 'text-text-primary',
      },
      grey: {
        headingStyles: 'text-blue-800',
        richText: 'text-text-primary',
      },
      dark: {
        headingStyles: 'text-white',
        richText: 'text-white/90',
      },
    },
    bodySize: {
      md: {
        richText: 'lg:text-baseLg',
      },
      lg: {},
      xl: {
        headingStyles: 'max-md:text-lg',
        richText: 'text-md leading-8 xl:text-lg xl:leading-tight',
      },
    },
  },
  defaultVariants: {
    blockAlignment: 'left',
    variant: 'light',
    bodySize: 'lg',
  },
});

// Export common text styles for reuse
export const blockTextStyles = {
  preHeading: blockHeaderStyles.slots.preHeadingStyles,
  richText: blockHeaderStyles.slots.richText,
};

export { BlockHeader };
